import React from "react";
import { makeStyles } from "@mui/styles";
import { useMoralis } from "react-moralis";
import { LoginComponent } from "./LoginComponent";
import { LaunchVault } from "./LaunchVault";
import { HeaderTab } from "./HeaderTab";

export default function DashBoard() {
  const { isAuthenticated, user } = useMoralis();

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.dashboard}>
        <HeaderTab />
        {!isAuthenticated ? <LoginComponent /> : <LaunchVault user={user} />}
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '100%',
    width: '100%',
  },

  dashboard: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: "95%",
    width: "97%",

  },
});
