import React from "react";
import { makeStyles } from "@mui/styles";

export const Manual = ({
  projectHash,
  handleReveal,
  isRevealed,
}) => {
  const classes = useStyles();

  return !projectHash ? (
    <h2 className={classes.updateText}>Contract Pending....</h2>
  ) : !isRevealed ? (
    <div className={classes.revealButton} onClick={handleReveal}>
      <p className={classes.buttonText}>Reveal </p>
    </div>
  ) : null;
};

const useStyles = makeStyles({
  launchButton: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    marginLeft: 20,
    background: "none",
    borderRadius: 100,
    fontWeight: "300",
  },
  updateText: {
    color: "red",
    fontSize: 22,
    fontWeight: "300",
    marginLeft: 20,
  },
  revealButton: {
    border: "1px solid #f84b67",
    padding: "8px 30px",
    marginLeft: 30,
    zIndex: 9999,
    cursor: 'pointer',
  },
  buttonText: {
    color: "#f84b67",
    fontWeight: "300",
  },
});
