import React from 'react'
import { makeStyles } from '@mui/styles'
import DatePicker from 'react-date-picker'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export const Calendar = ({value, handleDate}) => {
    const classes = useStyles()

    return (
        <DatePicker value={value} onChange={(value) => handleDate(value)} style={{color: 'white'}}className={classes.datePicker} calendarClassName={classes.picker} calendarIcon={ <CalendarTodayIcon style={{color: 'white',}} />} clearIcon={false} format="y-MM-dd" />
    )
}

const useStyles = makeStyles({
    datePicker:{
        background: '#242535',
        padding: 12,
        height: 80, 
    }, 
})
