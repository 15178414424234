import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import { useMoralis, useNewMoralisObject, useMoralisQuery } from "react-moralis";
import { Calendar } from "./Calendar";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/icons/deBotLogoTransparent.png";
import { BallTriangle } from "react-loader-spinner";
import { launchValidation } from "../utils";
import stealthIcon from "../assets/icons/stealthIcon.png";
import fairIcon from "../assets/icons/fairIcon.png";

export const LaunchFormModal = ({ isOpen, handleCloseModal }) => {
  const classes = useStyles();

  const { user } = useMoralis();
  const { isSaving, save } = useNewMoralisObject("Launches");
  const { Moralis } = useMoralis()

  const userId = user.id;

  const [isFairLaunch, setIsFairLaunch] = useState(true);
  const [isStealthLaunch, setIsStealthLaunch] = useState(false);
  const [launchType, setLaunchType] = useState(null);
  const [launchDate, setLaunchDate] = useState(null);

  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenTelegram, setTokenTelegram] = useState("");
  const [tokenWebsite, setTokenWebsite] = useState("");
  const [tokenDescription, setTokenDescription] = useState("");
  const [tokenHash, setTokenHash] = useState("");
  const [buyTax, setBuyTax] = useState("");
  const [sellTax, setSellTax] = useState("");
  const [maxTX, setMaxTX] = useState("");
  const [maxWallet, setMaxWallet] = useState("");

  const [hashError, setHashError] = useState("");
  const [formError, setFormError] = useState("");

  const handleLaunchSelection = (type) => {
    switch (type) {
      case "Fair":
        launchValidation("hash", tokenHash)
          .then(() => {
            setIsFairLaunch(true);
            setIsStealthLaunch(false);
            setLaunchType("Fair");
          })
          .catch((error) => setHashError(error.message));
        break;

      case "Stealth":
        validateToken(tokenHash).then((res) => {
          if (res) {
            setIsFairLaunch(false);
            setIsStealthLaunch(true);
            setLaunchType("Stealth");
          }
        }).catch((error) => setHashError("Invalid Token. Please Make sure your token has been launched"))
        // launchValidation("hash", tokenHash)
        //   .then(() => {
        //     setIsFairLaunch(false);
        //     setIsStealthLaunch(true);
        //     setLaunchType("Stealth");
        //   })
        //   .catch((error) => setHashError(error.message));
        break;

      default:
        break;
    }
  };

  const validateToken = async (token) => {
    const metaData = await Moralis.Web3API.token.getTokenMetadata({ chain: "bsc", addresses: `${token}` })
    return metaData
  }

  const generateBotKey = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let result = ''
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result

  }

  const handleSubmit = () => {

    const project = {
      tokenName,
      tokenSymbol,
      tokenHash,
      tokenTelegram,
      tokenWebsite,
      tokenDescription,
      buyTax,
      sellTax,
      maxTX,
      maxWallet,
      launchDate,
      launchType,
      userId,
      TGBotOwner: 0,
      TGChatroomId: 0,
      tgBotKey: generateBotKey(8)

    };

    launchValidation("project", project)
      .then((res) => {
        save(res).finally(() => window.location.reload(false));
      })
      .catch((error) => setFormError(error.message));
  };

  const handleDate = (date) => {
    setLaunchDate(date);
  };

  return (
    <Modal className={classes.modal} open={isOpen}>
      <div className={classes.modalContainer}>
        {isSaving ? (
          <BallTriangle width={90} color="#f84b67" arialLabel="loading" />
        ) : (
          <div className={classes.infoContainer}>
            {!launchType ? (
              <>
                <div
                  className={classes.closeButton}
                  onClick={() => {
                    setLaunchType(null);
                    setTokenHash("");
                    setHashError("");
                    handleCloseModal();
                  }}
                >
                  <CloseIcon style={{ fontSize: 40, color: "white" }} />
                </div>
                <div className={classes.headerLogo}>
                  <img src={logo} style={{ width: 250, marginLeft: -25 }} alt="DebotBSC Logo" />
                </div>
                <h2 className={classes.subTitle}>Choose a launch Type:</h2>
                <p className={classes.description}>
                  <span style={{ fontSize: 18, color: "red", fontWeight: "600" }}>
                    Fair Launch{" "}
                  </span>{" "}
                  will make your contract available to reaveal once a set timer
                  reaches zero. The users then have to complete a captcha in
                  order to reveal the contract.
                </p>
                <p className={classes.description}>
                  <span style={{ fontSize: 18, color: "red", fontWeight: "600" }}>
                    Stealth Launch{" "}
                  </span>{" "}
                  will make let you manually reveal the contract whenever you
                  want. Simple click “Reveal” in your dashboard to make the
                  contract available to the user
                </p>
                <p className={classes.inputLabel}>Contract Address:</p>
                <input
                  className={classes.input}
                  value={tokenHash}
                  onChange={(e) => setTokenHash(e.target.value)}
                />

                {!hashError ? (
                  <div style={{ marginTop: 40, marginBottom: 40 }}></div>
                ) : (
                  <p className={classes.errorText}>{hashError}</p>
                )}

                <div className={classes.selectionContainer}>
                  <button
                    className={
                      classes.disabledButton
                    }
                    disabled={true}
                    onClick={() => handleLaunchSelection("Fair")}
                  >
                    <img src={fairIcon} style={{ width: 100 }} alt="Fair Launch Logo" />
                    <h2 className={classes.selectionText} style={{ color: 'gray', }}>Coming Soon</h2>

                  </button>
                  <button
                    className={
                      tokenHash
                        ? classes.selectionButton
                        : classes.disabledButton
                    }
                    disabled={tokenHash ? false : true}
                    onClick={() => handleLaunchSelection("Stealth")}
                  >
                    <img src={stealthIcon} style={{ width: 100 }} alt="Stealth Launch Icon" />
                    <h2 className={classes.selectionText}>Stealth Launch</h2>
                  </button>
                </div>
              </>
            ) : (
              <div className={classes.formContainer}>
                <div
                  className={classes.closeButton}
                  onClick={() => {
                    setLaunchType(null);
                    setTokenHash("");
                    setFormError("");
                    handleCloseModal();
                  }}
                >
                  <CloseIcon style={{ fontSize: 40, color: "white" }} />
                </div>
                <div className={classes.headerLogo}>
                  <img src={logo} style={{ width: 250, marginLeft: -25 }} alt="DebotBsc Logo" />
                </div>
                <div className={classes.horizContainer}>
                  <h2 className={classes.subTitle}>
                    Create a {launchType} Launch
                  </h2>
                  <p style={{ color: "red", fontSize: 16, width: "50%" }}>
                    {formError}
                  </p>
                </div>
                <input
                  className={classes.input}
                  placeholder="Project Name"
                  onChange={(e) => setTokenName(e.target.value)}
                />
                <input
                  className={classes.input}
                  placeholder="TokenSymbol"
                  onChange={(e) => setTokenSymbol(e.target.value)}
                />
                <input
                  className={classes.input}
                  placeholder="Telegram"
                  onChange={(e) => setTokenTelegram(e.target.value)}
                />
                <input
                  className={classes.input}
                  placeholder="Website"
                  onChange={(e) => setTokenWebsite(e.target.value)}
                />
                <textarea
                  className={classes.largeInput}
                  onChange={(e) => setTokenDescription(e.target.value)}
                  placeholder="Description"
                  rows={4}
                ></textarea>
                <div className={classes.spreadForm}>
                  <div className={classes.smInputContainer}>
                    <p className={classes.smInputLabel}>Buy Tax:</p>
                    <input
                      onChange={(e) => setBuyTax(e.target.value)}
                      className={classes.smInput}
                    ></input>
                    <p
                      style={{
                        color: "gray",
                        marginLeft: 20,
                        fontSize: 24,
                        fontWeight: "300",
                      }}
                    >
                      %
                    </p>
                  </div>
                  <div className={classes.smInputContainer}>
                    <p className={classes.smInputLabel}>Sell Tax:</p>
                    <input
                      onChange={(e) => setSellTax(e.target.value)}
                      className={classes.smInput}
                    ></input>
                    <p
                      style={{
                        color: "gray",
                        marginLeft: 20,
                        fontSize: 24,
                        fontWeight: "300",
                      }}
                    >
                      %
                    </p>
                  </div>
                  <div className={classes.smInputContainer}>
                    <p className={classes.smInputLabel}>Max TX (optional):</p>
                    <input
                      onChange={(e) => setMaxTX(e.target.value)}
                      className={classes.smInput}
                    ></input>
                    <p
                      style={{
                        color: "gray",
                        marginLeft: 20,
                        fontSize: 24,
                        fontWeight: "300",
                      }}
                    >
                      %
                    </p>
                  </div>
                  <div className={classes.smInputContainer}>
                    <p className={classes.smInputLabel}>Max Wallet (optional):</p>
                    <input
                      onChange={(e) => setMaxWallet(e.target.value)}
                      className={classes.smInput}
                    ></input>
                    <p
                      style={{
                        color: "gray",
                        marginLeft: 20,
                        fontSize: 24,
                        fontWeight: "300",
                      }}
                    >
                      %
                    </p>
                  </div>
                </div>
                {launchType === "Stealth" ? null : (
                  <Calendar value={launchDate} handleDate={handleDate} />
                )}
                <div className={classes.submitButton} onClick={handleSubmit}>
                  <p className={classes.buttonText}>
                    {" "}
                    Create {launchType} Launch
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  headerLogo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  horizContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "90vh",
    width: "40vw",
    background: "#27293d",
    borderRadius: 12,
  },
  closeButton: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    cursor: "pointer",
    right: 0,
  },
  input: {
    color: "white",
    width: "95%",
    fontSize: 16,
    padding: 14,
    border: "none",
    background: "#242535",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  largeInput: {
    color: "white",
    width: "95%",
    fontSize: 16,
    padding: 14,
    border: "none",
    background: "#242535",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 8,
    resize: 'none',
  },
  formContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  launchSelector: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 530,
    height: 50,
  },
  selectedLaunchButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "48%",
    background: "tomato",
  },
  launchButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "48%",
    border: "2px solid tomato",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    textTransform: "uppercase",
    letterSpacing: 6,
  },
  submitButton: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "tomato",
    width: 440,
    height: 60,
  },
  infoContainer: {
    position: "relative",
    height: "90%",
    width: "85%",
  },
  selectionContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: "white",
    fontSize: 42,
    fontWeight: "600",
  },
  subTitle: {
    color: "white",
    fontWeight: "300",
    marginTop: 20,
    width: "50%",
    marginBottom: 20,
  },
  description: {
    marginBottom: 20,
    fontSize: 16,
    fontWeight: "300",
    color: "white",
  },
  selectionButton: {
    marginTop: 30,
    height: 300,
    width: "49%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#31334c",
    borderRadius: 12,
    cursor: "pointer",
  },
  errorText: {
    color: "red",
    fontSize: 18,
    fontWeight: "300",
    marginTop: 15,
    marginLeft: 20,
  },
  disabledButton: {
    marginTop: 30,
    height: 300,
    width: "49%",
    display: "flex",
    flexDirection: "column",
    background: "#26283a",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
  },
  selectionText: {
    marginTop: 20,
    color: "white",
    fontWeight: "400",
    fontSize: 30,
  },
  spreadForm: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    height: 150,
    width: "100%",
    marginTop: 15,
  },
  smInputContainer: {
    width: "40%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 50,
  },
  smInput: {
    color: "white",
    width: "30%",
    fontSize: 16,
    padding: 14,
    border: "none",
    background: "#242535",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  inputLabel: {
    color: "gray",
    fontWeight: "300",
    marginRight: 8,
  },
  smInputLabel: {
    color: "gray",
    fontWeight: "300",
    marginRight: 8,
    width: 85,
  },
});
