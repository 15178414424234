import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";

export const Timer = ({ timer, handleReveal, small }) => {
  const classes = useStyles();

  let interval = useRef();

  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  const startTimer = () => {
    const countdownDate = new Date(timer).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
        // handleReveal();
      } else {
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    });
    console.log(countdownDate);
  };

  useEffect(() => {
    startTimer();
    console.log(timer);
    return () => {
      clearInterval(interval.current);
    };
  }, [timer]);

  return (
    <div className={ !small ? classes.container : classes.smallContainer}>
      {!small ? (
        <p className={classes.timer}>
          Available in {days}:{hours}:{minutes}:{seconds}
        </p>
      ) : (
        <p className={classes.smallTimer}>
        {days}:{hours}:{minutes}:{seconds}
        </p>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginLeft: 30,
    borderRadius: 100,
  },
  smallContainer:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
  },
  timer: {
    color: "#f84b67",
    fontSize: 26,
    fontWeight: "300",
  },
  smallTimer:{
      color: '#f84b67',
      fontSize: 18,
      fontWeight: '500',
  }
});
