import React from "react";
import { makeStyles } from "@mui/styles";
import { useMoralis } from "react-moralis";
import MetaLogo from "../assets/icons/MetaLogo.svg";

export const LoginComponent = () => {
  const classes = useStyles();

  const { authenticate } = useMoralis();

  return (
    <div className={classes.container}>
      <div className={classes.loginContainer}>
        <img src={MetaLogo} style={{ width: 350 }} />
        <p className={classes.title}>Meta Mask Login</p>
        <p className={classes.subText}>
          Login using MetaMask to access your launch dashboard. Logging in is
          required to create a dashboard in order to create and manage your
          launches and to reduce spam in our database.
        </p>
        <div
          className={classes.loginButton}
          onClick={() =>
            authenticate({
              signingMessage: `I give this site permission to: \n \n Create an account and dashboard using my wallet address \n \n NOTE: This site cannot make transactions on your wallets behalf. All private wallet information such as keys and passwords, are securely stored and handled by MetaMask`,
            })
          }
        >
          <h2 className={classes.buttonText}>Login</h2>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "50%",
    borderRadius: 12,
  },
  title: {
    fontSize: 54,
    color: "white",
    textTransform: "uppercase",
    fontWeight: "800",
  },
  subTitle: {
    fontsize: 32,
    color: "#0A0226",
    textTransform: "uppercase",
    marginBottom: 8,
  },
  subText: {
    textAlign: "center",
    width: "70%",
    fontSize: 18,
    fontWeight: "300",
    color: "silver",
    marginBottom: 20,
  },
  loginButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    padding: "20px 0",
    width: 630,
    color: "white",
    background: "#f84b67",
    fontSize: 18,
    cursor: "pointer",
  },
  logo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 125,
    width: "80%",
  },
  divider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 80,
    width: "100%",
    marginTop: 24,
  },
  fx: {
    height: 1,
    width: 180,
    background: "white",
  },
  buttonText: {
    fontSize: 18,
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: 8,
  },
  loginContainer: {
    marginBottom: 80,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
